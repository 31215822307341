import axios from 'axios'
import history from './history'

const statusEnum = {
  ok: 200,
  created: 201,
  noContent: 204,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  conflict: 409,
  tooLarge: 413,
  unprocessableEntity: 422,
  serverError: 500,
  serviceUnavailable: 503,
}

const objectToGetParams = (object) => {
  return (
    '?' +
    Object.keys(object)
      .filter((key) => !!object[key])
      .map((key) => `${key}=${encodeURIComponent(object[key])}`)
      .join('&')
  )
}

class Http {
  constructor() {
    const service = axios.create({
      baseURL: process.env.API_HOST,
    })
    service.interceptors.request.use((config) => {
      const token = window.localStorage.getItem('token')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      const language = window.localStorage.getItem('language')
      if (language) {
        config.headers.Language = language
      }
      return config
    })
    service.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === statusEnum.unauthorized
        ) {
          if (!error.config.url.endsWith('signin')) {
            history.push('/signin')
          }
          return Promise.reject(error)
        } else {
          return Promise.reject(error)
        }
      }
    )
    this.service = service
  }

  formatResult = (result) => {
    const status = Object.entries(statusEnum)
      .filter((x) => x[1] === result.status)
      .map((y) => y[0])
    if (status.length >= 1) {
      result[status[0]] = true
    }
    return result
  }

  handleResponse = (response) => {
    let result
    if (!response) {
      result = {
        error: true,
        data: {
          error: 'Unknown error',
        },
      }
    } else {
      result = {
        status: response.status,
        error:
          !response.status.toString().startsWith('2') &&
          !(response.status === statusEnum.unauthorized),
        data: response.data,
      }
    }
    const format = this.formatResult(result)
    return format
  }

  handleError(error) {
    let result
    if (error && error.response) {
      result = {
        status: error.response.status,
        error: !error.response.status.toString().startsWith('2'),
        data: error.response.data,
      }
    } else {
      result = {
        error: true,
        data: {
          error: error,
        },
      }
    }
    const format = this.formatResult(result)
    return format
  }

  async get(path) {
    try {
      const response = await this.service.get(path)
      const responseResult = this.handleResponse(response)
      return responseResult
    } catch (e) {
      const errorResult = this.handleError(e)
      return errorResult
    }
  }

  async delete(path) {
    try {
      const response = await this.service.delete(path)
      return this.handleResponse(response)
    } catch (e) {
      return this.handleResponse(e.response)
    }
  }

  handlePostPatchPut = async (url, data, method) => {
    try {
      const response = await this.service.request({
        method,
        url,
        responseType: 'json',
        data,
      })
      return this.handleResponse(response)
    } catch (e) {
      const errorResult = this.handleError(e)
      return errorResult
    }
  }

  async patch(path, payload) {
    const result = await this.handlePostPatchPut(path, payload, 'PATCH')
    return result
  }

  async post(path, payload) {
    const result = await this.handlePostPatchPut(path, payload, 'POST')
    return result
  }

  async put(path, payload) {
    const result = await this.handlePostPatchPut(path, payload, 'PUT')
    return result
  }
}

const http = new Http()

export { http, objectToGetParams, statusEnum as status }
